import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import Swal from 'sweetalert2';

export const USER_TYPE_USER = "user";
export const USER_TYPE_MOD = "mod";
export const USER_TYPE_ADMIN = "admin";

export interface CategoryGroup {
  category_name : string;
}

@Injectable({
  providedIn: 'root'
})
export class DataMasterService {

  private userId : string = '';
  private userType : string = '';
  footerSticky: string = '';
  sideNavShow : boolean = false;

  userIdChange : EventEmitter<string> = new EventEmitter<string>();
  userTypeChange : EventEmitter<string> = new EventEmitter<string>();

  private httpOptions = {
    headers: new HttpHeaders()
  }

  // url_base : string = 'http://chiwi-dev.com/proviajes';
  url_base : string = 'https://prueba.proviaje.es';
  //url_base : string = 'https://www.proviaje.es';
  //url_base : string = 'http://localhost/angular/proviajes';

  url_seller : string = this.url_base + '/services/generaloperations/seller.php';
  url_log : string = this.url_base + '/services/generaloperations/log.php';
  url_seller_session : string = this.url_base + '/services/generaloperations/sellersession.php';
  url_moderator : string = this.url_base + '/services/generaloperations/moderator.php';
  url_moderator_session : string = this.url_base + '/services/generaloperations/moderatorsession.php';
  url_admin : string = this.url_base + '/services/generaloperations/admin.php';
  url_admin_session : string = this.url_base + '/services/generaloperations/adminsession.php';
  url_product : string = this.url_base + '/services/generaloperations/product.php';
  url_order : string = this.url_base + '/services/generaloperations/orders.php';
  url_course : string = this.url_base + '/services/generaloperations/grade.php';
  url_request : string = this.url_base + '/services/generaloperations/request.php';
  url_grade : string = this.url_base + '/services/generaloperations/grade.php';
  url_contact : string = this.url_base + '/services/generaloperations/contact_service.php';
  url_contact_sidenav : string = this.url_base + '/services/generaloperations/contact.php';
  url_category : string = this.url_base + '/services/generaloperations/category.php';
  url_pdf : string = this.url_base + '/services/generaloperations/generate_pdf_dynamic.php';
  url_excel : string = this.url_base + '/services/generaloperations/excel.php';
  constructor(private httpcli:HttpClient) { }

  getUserId() : string {
    return this.userId;
  }

  getFooter() : string {
    return this.footerSticky;
  }

  setFooter(sticky : string) : void {
    this.footerSticky = sticky;
  }

  //nacabar
  getSideNav() : boolean {
    return this.sideNavShow;
  }

  setSideNav(value) : void {
    this.sideNavShow = value;
  }

  setUserId(userId : string) : void {
    this.userId = userId;
    this.userIdChange.emit(userId);
  }

  getUserType() : string {
    return this.userType;
  }

  setUserType(userType : string) : void {
    this.userType = userType;
    this.userTypeChange.emit(userType)
  }

  login(username : string, password : string, userType : string) : Observable<any> | null {
    switch (userType) {
      case USER_TYPE_USER: {
        return this.loginUser(username, password);
        break;
      }
      case USER_TYPE_MOD: {
        return this.loginMod(username, password);
        break;
      }
      case USER_TYPE_ADMIN: {
        return this.loginAdmin(username, password);
        break;
      }
      default: {
        return null;
        break;
      }
    }
  }

  checkSession(token : string, userType : string) : Observable<boolean> {
    //console.log('checkSession', userType);

    switch (userType) {
      case USER_TYPE_USER: {
        return this.checkUserSession(token);
        break;
      }
      case USER_TYPE_MOD: {
        return this.checkModSession(token);
        break;
      }
      case USER_TYPE_ADMIN: {
        return this.checkAdminSession(token);
        break;
      }
      default: {
        console.log("Invalid session type");
        return of(false);
        break;
      }
    }
  }

  private loginUser(username : string, password : string) : Observable<any> {
    const url = `${this.url_seller}?action=checkseller`;

    let formData : FormData = new FormData();
    formData.append('seller_email', username);
    formData.append('seller_password', password);

    return this.httpcli.post<any>(url, formData);

    // return new Observable<any>((subscriber) => {
    //   if (username == USER_TYPE_USER && password == USER_TYPE_USER) {
    //     subscriber.next("token_prueba");
    //     subscriber.complete();
    //     return;
    //   }
    //   subscriber.next("error");
    //   subscriber.complete();
    // });
  }

  private checkUserSession(token : string) : Observable<boolean> {
    return new Observable<boolean>((subscriber) => {
      //console.log("checkUserSession");
      if (token == null) {
        subscriber.next(false);
        subscriber.complete();
        return;
      }

      this.httpOptions.headers = new HttpHeaders();
      this.httpOptions.headers = this.httpOptions.headers.append('Authorization', token);

      const url = `${this.url_seller_session}?action=verify`;
      this.httpcli.post<any>(url, null, this.httpOptions).subscribe(responseUser => {
        if(isNaN(responseUser)) {
          sessionStorage.removeItem('session_token');
          subscriber.next(false);
          subscriber.complete();
          return;
        }
        //verify status
        let formData : FormData = new FormData();
        formData.append('seller_id', responseUser);
        //console.log(formData);
        const urlStatus = `${this.url_seller_session}?action=status`;
        this.httpcli.post<any>(urlStatus, formData, this.httpOptions).subscribe(responseStatus => {
          //console.log("status");
          //console.log(responseStatus);
          if(responseStatus[1][0].seller_status == "0") {
            sessionStorage.removeItem('session_token');
            subscriber.next(false);
            subscriber.complete();
            return;
          }
          this.setUserId(responseUser);
          this.setUserType(USER_TYPE_USER);
          subscriber.next(true);
          subscriber.complete();
        });
        
      });
    });
  }

  private loginMod(username : string, password : string) : Observable<any> {
    const url = `${this.url_moderator}?action=checkmoderator`;

    let formData : FormData = new FormData();
    formData.append('moderator_email', username);
    formData.append('moderator_password', password);

    return this.httpcli.post<any>(url, formData);

    // return new Observable<any>((subscriber) => {
    //   if (username == USER_TYPE_MOD && password == USER_TYPE_MOD) {
    //     subscriber.next("token_prueba");
    //     subscriber.complete();
    //     return;
    //   }
    //   subscriber.next("error");
    //   subscriber.complete();
    // });
  }

  private checkModSession(token : string) : Observable<boolean> {
    return new Observable<boolean>((subscriber) => {
      console.log("checkModSession");
      if (token == null) {
        subscriber.next(false);
        subscriber.complete();
        return;
      }

      this.httpOptions.headers = new HttpHeaders();
      this.httpOptions.headers = this.httpOptions.headers.append('Authorization', token);

      const url = `${this.url_moderator_session}?action=verify`;
      this.httpcli.post<any>(url, null, this.httpOptions).subscribe(response => {
        console.log(response);

        if(isNaN(response)) {
          sessionStorage.removeItem('session_token');
          subscriber.next(false);
          subscriber.complete();
          return;
        }
        this.setUserId(response);
        this.setUserType(USER_TYPE_MOD);
        subscriber.next(true);
        subscriber.complete();
      });
    });
  }

  private loginAdmin(username : string, password : string) : Observable<any> {
    const url = `${this.url_admin}?action=checkadmin`;

    let formData : FormData = new FormData();
    formData.append('admin_email', username);
    formData.append('admin_password', password);

    return this.httpcli.post<any>(url, formData);

    // return new Observable<any>((subscriber) => {
    //   if (username == USER_TYPE_ADMIN && password == USER_TYPE_ADMIN) {
    //     subscriber.next("token_prueba");
    //     subscriber.complete();
    //     return;
    //   }
    //   subscriber.next("error");
    //   subscriber.complete();
    // });
  }

  private checkAdminSession(token : string) : Observable<boolean> {
    return new Observable<boolean>((subscriber) => {
      //console.log("checkAdminSession");
      if (token == null) {
        subscriber.next(false);
        subscriber.complete();
        return;
      }

      this.httpOptions.headers = new HttpHeaders();
      this.httpOptions.headers = this.httpOptions.headers.append('Authorization', token);

      const url = `${this.url_admin_session}?action=verify`;
      this.httpcli.post<any>(url, null, this.httpOptions).subscribe(response => {
        if(isNaN(response)) {
          sessionStorage.removeItem('session_token');
          subscriber.next(false);
          subscriber.complete();
          return;
        }
        this.setUserId(response);
        this.setUserType(USER_TYPE_ADMIN);
        subscriber.next(true);
        subscriber.complete();
      });
    });
  }

  /*register user*/
  regUser( seller_name : string, seller_password : string,seller_lastname:string,
            seller_email:string,grade_id:string,seller_phonenumber:string)  {
    let formData : FormData = new FormData();
    formData.append('seller_name', seller_name);
    formData.append('seller_password', seller_password);
    formData.append('seller_lastname', seller_lastname);
    formData.append('seller_email', seller_email);
    formData.append('grade_id', grade_id);
    formData.append('seller_phonenumber', seller_phonenumber);
    return this.httpcli
      .post(`${this.url_seller}?action=insert`, formData);
  }
  
  /*insert log*/
  insertLog( user_id : string, moderator_id : string, admin_id:string,
    description : string, name? : string, lastname? : string, institution? : string)  {
    let formData : FormData = new FormData();
    formData.append('user_id', user_id);
    formData.append('moderator_id', moderator_id);
    formData.append('admin_id', admin_id);
    formData.append('description', description);
    if (name != null && lastname != null && institution != null) {
      formData.append('name', name);
      formData.append('lastname', lastname);
      formData.append('institution', institution);
    }
    
    return this.httpcli
    .post(`${this.url_log}?action=insert`, formData);
  }

  /*register product*/
  regProduct( product_name : string, category_id : number,product_quantity:number,
            product_treshold:number,product_generate:number,
            product_sellerprice:number,
            product_saleprice:number,
            product_image:string,
            product_description : string)  {
    let formData : FormData = new FormData();
    formData.append('product_name', product_name);
    formData.append('category_id', category_id.toString());
    formData.append('product_quantity', product_quantity.toString());
    formData.append('product_treshold', product_treshold.toString());
    formData.append('product_generate', product_generate.toString());
    formData.append('product_sellerprice', product_sellerprice.toString());
    formData.append('product_saleprice', product_saleprice.toString());
    formData.append('product_image', product_image);
    formData.append('product_description', product_description);
    return this.httpcli
      .post(`${this.url_product}?action=insert`, formData);
  }

  /*get all product*/
  getAllProducts(){
    const url = `${this.url_product}?action=getall`;
    return this.httpcli.get<any>(url);
  }

  /*get all product*/
  getAllCategorys(){
    const url = `${this.url_category}?action=getall`;
    return this.httpcli.get<any>(url);
  }

  /*get all orders r*/
  getAllOrders(){
    const url = `${this.url_order}?action=getall`;
    return this.httpcli.get<any>(url);
  }
  /*get all users*/
  getAllSellers(){
    const url = `${this.url_seller}?action=getall`;
    return this.httpcli.get<any>(url);
  }
  /*get all request*/
  getAllRequest(){
    const url = `${this.url_request}?action=getall`;
    return this.httpcli.get<any>(url);
  }
  /*get all moderators*/
  getAllModerators(){
    const url = `${this.url_moderator}?action=getall`;
    return this.httpcli.get<any>(url);
  }

  /*get contact*/
  getContact(){
    const url = `${this.url_contact_sidenav}?action=get`;
    return this.httpcli.get<any>(url);
  }

  /*update contact*/
  updateContact(id : number, phone_number : string, email : string) {

    let formData : FormData = new FormData();
    formData.append('id', id.toString());
    formData.append('phone_number', phone_number);
    formData.append('email', email);

    return this.httpcli.post(`${this.url_contact_sidenav}?action=update`, formData);
  }

  getProduct(productId : number) {
    const url = `${this.url_product}?action=search&product_id=${productId}`;
    return this.httpcli.get<any>(url);
  }

  getProductbyCategory(category_id : number) {
    const url = `${this.url_product}?action=search&category_id=${category_id}`;
    return this.httpcli.get<any>(url);
  }

  getCategoryById(category_id : number) {
    const url = `${this.url_category}?action=get&category_id=${category_id}`;
    return this.httpcli.get<any>(url);
  }
  getCategoryByName(category_name : number) {
    const url = `${this.url_category}?action=get&category_name=${category_name}`;
    return this.httpcli.get<any>(url);
  }

  /*get seller*/
  getSeller(sellerId : number) {
    const url = `${this.url_seller}?action=get&seller_id=${sellerId}`;
    return this.httpcli.get<any>(url);
  }
  /*get one request*/
  getRequest(requestId : number) {
    const url = `${this.url_request}?action=get&request_id=${requestId}`;
    return this.httpcli.get<any>(url);
  }

  /*get  request by grade id*/
  getRequestGradeId(grade_id : string) {
    const url = `${this.url_request}?action=get&grade_id=${grade_id}`;
    return this.httpcli.get<any>(url);
  }

  /*get  request by user id*/
  getRequestUser(sellerId : number) {
    const url = `${this.url_request}?action=get&seller_id=${sellerId}`;
    return this.httpcli.get<any>(url);
  }

  getRequestByModerator(moderatorId : number) {
    const url = `${this.url_request}?action=get&moderator_id=${moderatorId}`;
    return this.httpcli.get<any>(url);
  }

  getRequestDetails(requestId : number) {
    const url = `${this.url_request}?action=getdetails&request_id=${requestId}`;
    return this.httpcli.get<any>(url);
  }

  getModerator(moderatorId : number) {
    const url = `${this.url_moderator}?action=get&moderator_id=${moderatorId}`;
    return this.httpcli.get<any>(url);
  }

  /*get course*/
  getCourse(courseId : number) {
    const url = `${this.url_course}?action=get&grade_id=${courseId}`;
    return this.httpcli.get<any>(url);
  }

  getCourseByModerator(moderatorId : number) {
    const url = `${this.url_course}?action=get&moderator_id=${moderatorId}`;
    return this.httpcli.get<any>(url);
  }

  /*get orders*/
  getOrdersGradeId(grade_id : string) {
    const url = `${this.url_order}?action=get&grade_id=${grade_id}`;
    return this.httpcli.get<any>(url);
  }

  /*get orders*/
  getOrdersId(orders_id : number) {
    const url = `${this.url_order}?action=get&orders_id=${orders_id}`;
    return this.httpcli.get<any>(url);
  }
  /*get orders details*/
  getOrdersDetail(order_id : number) {
    const url = `${this.url_order}?action=getdetails&orders_id=${order_id}`;
    return this.httpcli.get<any>(url);
  }
  /*get order categories*/
  getOrderCategories(order_id : number) {
    const url = `${this.url_order}?action=getOrdersCategory&orders_id=${order_id}`;
    return this.httpcli.get<any>(url);
  }
  /*generate pdf dinamic*/
  generatePdf(order_id : number, categories : any) {

    let formData : FormData = new FormData();
    formData.append('order_id', order_id.toString());
    formData.append('categories', categories);
    console.log(categories);
    return this.httpcli
      .post(`${this.url_pdf}?orders_id=${order_id}`, formData);
  }
  /*get grade*/
  getGrade(moderator_id : number) {
    const url = `${this.url_grade}?action=get&moderator_id=${moderator_id}`;
    return this.httpcli.get<any>(url);
  }
  
  /*get grade*/
  getAllGrade() {
    const url = `${this.url_grade}?action=getall`;
    return this.httpcli.get<any>(url);
  }

  /*get grade by id*/
  getGradeId(grade_id : string) {
    const url = `${this.url_grade}?action=get&grade_id=${grade_id}`;
    return this.httpcli.get<any>(url);
  }

  getGradeBySellerId(seller_id : number) {
    const url = `${this.url_grade}?action=get&seller_id=${seller_id}`;
    return this.httpcli.get<any>(url);
  }
  
  /*get web status*/
  getWebStatus() {
    const url = `${this.url_admin}?action=getWebStatus`;
    return this.httpcli.get<any>(url);
  }

  /*get  profit*/
  getProfit(seller_id : number) {
    const url = `${this.url_seller}?action=getprofit&seller_id=${seller_id}`;
    return this.httpcli.get<any>(url);
  }
  /*get  user by grade id*/
  getUserGradeId(grade_id : string) {
    const url = `${this.url_seller}?action=get&grade_id=${grade_id}`;
    return this.httpcli.get<any>(url);
  }

  getLastRequest(seller_id : number) {
    const url = `${this.url_request}?action=getlast&seller_id=${seller_id}`;
    return this.httpcli.get<any>(url);

  }

  getTicketCoefficient() {
    const url = `${this.url_product}?action=getcoefficient`;
    return this.httpcli.get<any>(url);
  }

  /*update request*/
  updateRequest(request_id : number, request_modification : string, request_details? : string) {

    let formData : FormData = new FormData();
    formData.append('request_id', request_id.toString());
    formData.append('request_modification', request_modification);

    if (request_details != null) {
      formData.append('request_details', request_details);
    }

    return this.httpcli
      .post(`${this.url_request}?action=update`, formData);
  }
  
  /*update web status*/
  updateWebStatus(status : number, version : string, disabled : number) {

    let formData : FormData = new FormData();
    formData.append('status', status.toString());
    formData.append('version', version);
    formData.append('disabled', disabled.toString());

    return this.httpcli
      .post(`${this.url_admin}?action=updateWeb`, formData);
  }

  /*update seller*/
  updateSeller(seller_id : number, seller_name : string,
    seller_lastname : string, seller_email : string, seller_phonenumber : string,
    grade_id : number, seller_password? : string) {

    let formData : FormData = new FormData();
    formData.append('seller_id', seller_id.toString());
    formData.append('seller_name', seller_name);
    formData.append('seller_lastname', seller_lastname);
    formData.append('seller_email', seller_email);
    formData.append('seller_phonenumber', seller_phonenumber);
    formData.append('grade_id', grade_id.toString());

    if (seller_password != null) {
      formData.append('seller_password', seller_password);
    }

    return this.httpcli
      .post(`${this.url_seller}?action=update`, formData);
  }

  /*update moderator*/
  updateModerator(moderator_id : number, moderator_name : string,
    moderator_lastname : string, moderator_email : string,
    moderator_phonenumber : string, moderator_overprice : number, moderator_institution : string,
    moderator_location : string, moderator_saleprice? : number, moderator_password? : string) {


    console.log({
      moderator_id,
      moderator_name,
      moderator_lastname,
      moderator_email,
      moderator_phonenumber,
      moderator_overprice,
      moderator_saleprice,
      moderator_institution,
      moderator_location,
      moderator_password
    });

    let formData : FormData = new FormData();
    formData.append('moderator_id', moderator_id.toString());
    formData.append('moderator_name', moderator_name);
    formData.append('moderator_lastname', moderator_lastname);
    formData.append('moderator_email', moderator_email);
    formData.append('moderator_phonenumber', moderator_phonenumber);
    formData.append('moderator_overprice', (+moderator_overprice).toString());
    formData.append('moderator_saleprice', (+moderator_saleprice).toString());
    formData.append('moderator_location', moderator_location);
    formData.append('moderator_institution', moderator_institution);
    if (moderator_password != null) {
      formData.append('moderator_password', moderator_password);
    }
    return this.httpcli
      .post(`${this.url_moderator}?action=update`, formData);
  }
  
  updateModeratorDate(moderator_id : number, moderator_date : string) {

    console.log({
      moderator_id,
      moderator_date
    });

    let formData : FormData = new FormData();
    formData.append('moderator_id', moderator_id.toString());
    formData.append('moderator_date', moderator_date);
    return this.httpcli
      .post(`${this.url_moderator}?action=update`, formData);
  }

  /*register moderator*/
  regModerator(moderator_name : string, moderator_password : string,
    moderator_lastname : string, moderator_email : string,
    moderator_phonenumber : string, moderator_institution : string,
    moderator_overprice : number, moderator_location : string, moderator_saleprice : number)  {

    let formData : FormData = new FormData();
    formData.append('moderator_name', moderator_name);
    formData.append('moderator_password', moderator_password);
    formData.append('moderator_lastname', moderator_lastname);
    formData.append('moderator_email', moderator_email);
    formData.append('moderator_phonenumber', moderator_phonenumber);
    formData.append('moderator_institution', moderator_institution);
    formData.append('moderator_overprice', moderator_overprice.toString());
    formData.append('moderator_saleprice', moderator_saleprice.toString());
    formData.append('moderator_location', moderator_location);
    return this.httpcli
      .post(`${this.url_moderator}?action=insert`, formData);
  }

  /*update product*/
  updateProduct(product_id:number,
    category_id:number,
    product_name:string,
    product_saleprice:number,
    product_sellerprice:number ,
    product_quantity:number,
    product_treshold:number,
    product_generate:boolean,
    product_image:string,
    product_description : string) {

    let formData : FormData = new FormData();
    formData.append('product_id', product_id.toString());
    formData.append('product_name', product_name);
    formData.append('product_saleprice', product_saleprice.toString());
    formData.append('category_id', category_id.toString());
    formData.append('product_sellerprice', product_sellerprice.toString());
    formData.append('product_quantity', product_quantity.toString());
    formData.append('product_treshold', product_treshold.toString());
    formData.append('product_generate', product_generate ? '1' : '0');
    console.log(product_generate);
    


    if (product_image != null) {
      formData.append('product_image', product_image);
    }

    formData.append('product_description', product_description);
    return this.httpcli
      .post(`${this.url_product}?action=update`, formData);
  }

  /*update stock product*/
  updateStockProduct(product_id:number,product_quantity:number ) {
    let formData : FormData = new FormData();
    formData.append('product_id', product_id.toString());
    formData.append('product_quantity', product_quantity.toString());
    return this.httpcli
      .post(`${this.url_product}?action=update`, formData);
  }

  updateOrders(orders_id : number, orders_readed : string) {
    let formData : FormData = new FormData();
    formData.append('orders_id', orders_id.toString());
    formData.append('orders_readed', orders_readed.toString());
    return this.httpcli
      .post(`${this.url_order}?action=update`, formData);
  }

  updateCategory(category_id : number, category_name : string) {
    let formData : FormData = new FormData();
    formData.append('category_id', category_id.toString());
    formData.append('category_name', category_name.toString());
    return this.httpcli
      .post(`${this.url_category}?action=update`, formData);
  }

  /*search product*/
  searchproduct(name:string) {
    const url = `${this.url_product}?action=search&product_name=${name}`;
    return this.httpcli.get<any>(url);
  }

  /*search product name*/
  searchProductName(name:string) {
    const url = `${this.url_product}?action=getByName&product_name=${name}`;
    return this.httpcli.get<any>(url);
  }

  /*insert request*/
  insertRequest( request_modification:string,request_details:string,seller_id:number)  {
    let formData : FormData = new FormData();
    formData.append('request_details', request_details);
    formData.append('request_modification', request_modification);
    formData.append('seller_id', seller_id.toString());
    return this.httpcli
      .post(`${this.url_request}?action=insert`, formData);
  }

  insertCategory( category_name:string)  {
    let formData : FormData = new FormData();
    formData.append('category_name', category_name);
    return this.httpcli
      .post(`${this.url_category}?action=insert`, formData);
  }

  /*insert requestdetails*/
  insertRequestdetails( requestdetails_quantity : number,requestdetails_sellerprice:number,requestdetails_saleprice:number,product_id:number,request_id:number)  {
    let formData : FormData = new FormData();
    formData.append('requestdetails_quantity', requestdetails_quantity.toString());
    formData.append('requestdetails_saleprice', requestdetails_saleprice.toString());
    formData.append('requestdetails_sellerprice', requestdetails_sellerprice.toString());
    formData.append('product_id', product_id.toString());
    formData.append('request_id', request_id.toString());
    return this.httpcli
      .post(`${this.url_request}?action=insertdetails`, formData);
  }

  /*insert order*/
  insertOrder( orders_date:string,orders_details:string,grade_id:string)  {
    let formData : FormData = new FormData();
    formData.append('orders_date', orders_date);
    formData.append('grade_id', grade_id);
    formData.append('orders_details', orders_details);
    return this.httpcli
      .post(`${this.url_order}?action=insert`, formData);
  }

  /*insert order*/
  insertExcel( institute : string, moderator: string, course: string, total_to_pay: string, income: string, benefit: string, quantity: string, generate: string, excel_sheets : string)  {
    let formData : FormData = new FormData();
    formData.append('institute', institute);
    formData.append('moderator', moderator);
    formData.append('course', course);
    formData.append('total_to_pay', total_to_pay);
    formData.append('income', income);
    formData.append('benefit', benefit);
    formData.append('quantity', quantity);
    formData.append('generate', generate);
    formData.append('excel_sheets', excel_sheets);
    return this.httpcli
      .post(`${this.url_excel}?action=insert`, formData);
  }
  
  /*get all logs*/
  getAllLogs() {
    const url = `${this.url_log}?action=getall`;
    return this.httpcli.get<any>(url);
  }

  /*get all excel*/
  getAllExcel() {
    const url = `${this.url_excel}?action=getall`;
    return this.httpcli.get<any>(url);
  }

  getSheets(id) {
    const url = `${this.url_excel}?action=getExcelSheets&id=${id}`;
    return this.httpcli.get<any>(url);
  }

  getSheetsProducts(id) {
    const url = `${this.url_excel}?action=getExcelSheetsProducts&id=${id}`;
    return this.httpcli.get<any>(url);
  }

  /*insert orderdetails*/
  insertOrderDetails( order_id : number,request_id:number)  {
    let formData : FormData = new FormData();
    formData.append('order_id', order_id.toString());
    formData.append('request_id', request_id.toString());
    return this.httpcli
      .post(`${this.url_order}?action=insertdetails`, formData);
  }

  deleteGrade(grade_id : number) {
    let formData : FormData = new FormData();
    formData.append('grade_id', grade_id.toString());
    return this.httpcli
      .post(`${this.url_grade}?action=delete`, formData);
  }

  deleteCategory(category_id : number) {
    let formData : FormData = new FormData();
    formData.append('category_id', category_id.toString());
    return this.httpcli
      .post(`${this.url_category}?action=delete`, formData);
  }
  /*delete seller*/
  deleteUser( seller_id : number)  {
    let formData : FormData = new FormData();
    formData.append('seller_id', seller_id.toString());
    return this.httpcli
      .post(`${this.url_seller}?action=delete`, formData);
  }
  /*delete moderator*/
  deleteModerator( moderator_id : number)  {
    let formData : FormData = new FormData();
    formData.append('moderator_id', moderator_id.toString());
    return this.httpcli
      .post(`${this.url_moderator}?action=delete`, formData);
  }
  /*delete request*/
  deleteRequest( request_id : number)  {
    let formData : FormData = new FormData();
    formData.append('request_id', request_id.toString());
    return this.httpcli
      .post(`${this.url_request}?action=delete`, formData);
  }

  deleteRequestDetails(requestId : number, productId : number) {
    let formData : FormData = new FormData();
    formData.append('request_id', requestId.toString());
    formData.append('product_id', productId.toString());
    return this.httpcli
      .post(`${this.url_request}?action=deletedetails`, formData);
  }
  /*update password user*/
  updatePasswordUser( seller_id : number,seller_password :string)  {
    let formData : FormData = new FormData();
    formData.append('seller_id', seller_id.toString());
    formData.append('seller_password', seller_password);
    return this.httpcli
      .post(`${this.url_seller}?action=update`, formData);
  }
  /*update password mod*/
  updatePasswordMod( moderator_id : number,moderator_password :string)  {
    let formData : FormData = new FormData();
    formData.append('moderator_id', moderator_id.toString());
    formData.append('moderator_password', moderator_password);
    return this.httpcli
      .post(`${this.url_moderator}?action=update`, formData);
  }
  /*update password admin*/
  updatePasswordAdmin( admin_id : number,admin_password :string)  {
    let formData : FormData = new FormData();
    formData.append('admin_id', admin_id.toString());
    formData.append('admin_password', admin_password);
    return this.httpcli
      .post(`${this.url_admin}?action=update`, formData);
  }
  /*register grade*/
  regGrade( grade_name : string, moderator_id : number)  {
    let formData : FormData = new FormData();
    formData.append('grade_name', grade_name);
    formData.append('moderator_id', moderator_id.toString());
    return this.httpcli
      .post(`${this.url_grade}?action=insert`, formData);
  }

  setSellerStatus(sellerId : number, status : number) {
    let formData : FormData = new FormData();
    formData.append('seller_id', sellerId.toString());
    formData.append('seller_status', status.toString());
    return this.httpcli
      .post(`${this.url_seller}?action=update`, formData);
  }

  setModeratorStatus(moderatorId : number, status : number) {
    let formData : FormData = new FormData();
    formData.append('moderator_id', moderatorId.toString());
    formData.append('moderator_status', status.toString());
    return this.httpcli
      .post(`${this.url_moderator}?action=update`, formData);
  }

  sendContactForm({ name, phone, email, centerName, message } : any) {
    let formData : FormData = new FormData();
    formData.append('issuer_email', email);
    formData.append('issuer_name', name);
    if (phone != null) {
      formData.append('issuer_phone', phone);
    }
    if (centerName != null) {
      formData.append('issuer_institute', centerName);
    }
    formData.append('message_content', message);
    return this.httpcli
      .post(`${this.url_contact}`, formData);
  }

  public sendRecover({ seller_email, moderator_email } : any) {
    let formData : FormData = new FormData();
    if (seller_email != null) {
      formData.append('seller_email', seller_email);
      return this.httpcli.post(`${this.url_seller}?action=request-recover`, formData);
    } else if (moderator_email) {
      formData.append('moderator_email', moderator_email);
      return this.httpcli.post(`${this.url_moderator}?action=request-recover`, formData);
    }
  }

  public recoverPassword({ token, seller_password, moderator_password } : any) {
    let formData : FormData = new FormData();
    formData.append('token', token);
    if (seller_password != null) {
      console.log('seller_password');
      formData.append('seller_password', seller_password);
      return this.httpcli.post(`${this.url_seller}?action=recover`, formData);
    } else if (moderator_password) {
      console.log('moderator_password');
      formData.append('moderator_password', moderator_password);
      return this.httpcli.post(`${this.url_moderator}?action=recover`, formData);
    }
  }

  public showMessage(aux : any, text? : string, icon? : any) : Promise<any> {
    if (typeof aux == 'object') {
      return Swal.fire({
        ...aux,
        allowEscapeKey : false,
        allowOutsideClick: false,
        heightAuto: false
      });
    }
    return Swal.fire({
      title: aux,
      text,
      icon,
      allowEscapeKey : false,
      allowOutsideClick: false,
      heightAuto: false
    });
  }
}
